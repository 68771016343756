<template>
  <div class="relative flex justify-center items-center min-h-screen p-4 bg-black overflow-hidden">
    <div class="absolute inset-0 z-0 pointer-events-none">
      <canvas id="particles"></canvas>
    </div>

    <div class="absolute inset-0 bg-gradient-to-br from-black via-purple-900 to-purple-800 opacity-80"></div>

    <div
      class="relative z-10 p-8 bg-black/70 border border-purple-800 rounded-3xl shadow-lg w-full max-w-md backdrop-blur-lg">
      <div class="text-center mb-10">
        <img src="@/assets/logo.png" alt="Company Logo" class="mx-auto h-20 w-auto">
        <h1 class="text-3xl font-extrabold text-purple-400 mt-6 tracking-wide glow">Bem-vindo(a) ao ControlEase</h1>
        <p class="text-sm text-gray-300">Faça login para acessar sua conta</p>
      </div>
      <form @submit.prevent="login" class="space-y-6">
        <div>
          <label for="username" class="block text-sm font-medium text-gray-300 mb-2">Usuário</label>
          <input id="username" v-model="username" type="text" placeholder="Digite seu usuário" required
            class="w-full px-4 py-3 bg-transparent border border-gray-600 rounded-lg text-white text-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-300 ease-in-out glow-input" />
        </div>
        <div>
          <label for="password" class="block text-sm font-medium text-gray-300 mb-2">Senha</label>
          <input id="password" v-model="password" type="password" placeholder="Digite sua senha" required
            class="w-full px-4 py-3 bg-transparent border border-gray-600 rounded-lg text-white text-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-300 ease-in-out glow-input" />
        </div>
        <button type="submit"
          class="w-full bg-gradient-to-r from-purple-600 to-purple-800 hover:from-purple-500 hover:to-purple-700 text-white font-semibold py-3 rounded-lg shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-neon">
          Entrar
        </button>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import authService from '@/service/authService';
import { useRouter } from 'vue-router';

const username = ref('');
const password = ref('');
const router = useRouter();

const login = async () => {
  const result = await authService.login({
    username: username.value,
    password: password.value,
  });
  if (result.success) {
    router.push('/user/dashboard');
  } else {
    alert('Falha no login: ' + result.message);
  }
};

const initParticles = () => {
  const canvas = document.getElementById('particles');
  const ctx = canvas.getContext('2d');

  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  let particles = Array(100).fill().map(() => ({
    x: Math.random() * canvas.width,
    y: Math.random() * canvas.height,
    radius: Math.random() * 3 + 1,
    dx: Math.random() * 2 - 1,
    dy: Math.random() * 2 - 1,
  }));

  const animate = () => {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    particles.forEach((particle) => {
      ctx.beginPath();
      ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
      ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
      ctx.fill();
      particle.x += particle.dx;
      particle.y += particle.dy;

      if (particle.x < 0 || particle.x > canvas.width) particle.dx *= -1;
      if (particle.y < 0 || particle.y > canvas.height) particle.dy *= -1;
    });

    requestAnimationFrame(animate);
  };

  animate();
};

onMounted(() => {
  initParticles();
});
</script>

<style scoped>
body {
  font-family: 'Inter', sans-serif;
}

.glow {
  text-shadow: 0 0 8px rgba(128, 0, 128, 0.8), 0 0 15px rgba(128, 0, 128, 0.6);
}

.glow-input:focus {
  box-shadow: 0 0 5px rgba(128, 0, 128, 0.5), 0 0 10px rgba(128, 0, 128, 0.3);
}

.hover\:shadow-neon:hover {
  box-shadow: 0 0 10px rgba(128, 0, 255, 0.8), 0 0 20px rgba(128, 0, 255, 0.6);
}
</style>
