<template>
  <div class="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-gray-800">
    <div class="w-full max-w-6xl bg-white shadow-lg rounded-lg p-6 sm:p-8 mx-auto my-10 text-center">
      <h2 class="text-2xl sm:text-4xl font-bold text-purple-600 mb-4">
        <font-awesome-icon icon="chart-line" class="mr-2" />
        Seja Bem-vindo(a), <span>{{ username }}</span> ao <span class="text-purple-700">ControlEase</span>
      </h2>
      <p class="text-sm sm:text-lg text-gray-600 mb-6">
        Gerencie suas finanças com simplicidade e eficiência.
      </p>
      <div class="overflow-hidden rounded-lg shadow-md">
        <img src="https://i.ibb.co/s1RzSXs/Preto-e-Marrom-Flatlay-Foto-Financ-as-Capa-para-Facebook.png"
          alt="Dashboard Image"
          class="w-full h-40 sm:h-56 object-cover transform transition-transform duration-500 hover:scale-105" />
      </div>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 px-4 sm:px-6 w-full max-w-4xl mt-6">
      <router-link to="/user/gastos"
        class="bg-gradient-to-br from-purple-500 via-pink-500 to-red-500 p-5 sm:p-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
        <h3 class="text-xl sm:text-2xl font-semibold text-white mb-2">Visão Geral</h3>
        <p class="text-sm sm:text-base text-white">Acompanhe suas finanças e tenha controle completo.</p>
      </router-link>

      <router-link to="/user/relatorios"
        class="bg-gradient-to-br from-green-400 via-blue-500 to-indigo-500 p-5 sm:p-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
        <h3 class="text-xl sm:text-2xl font-semibold text-white mb-2">Relatórios</h3>
        <p class="text-sm sm:text-base text-white">Gere relatórios financeiros detalhados dos seus gastos mensais.</p>
      </router-link>

      <router-link to="/user/tarefas"
        class="bg-gradient-to-br from-yellow-400 via-orange-500 to-red-500 p-5 sm:p-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
        <h3 class="text-xl sm:text-2xl font-semibold text-white mb-2">Tarefas</h3>
        <p class="text-sm sm:text-base text-white">Organize e acompanhe suas tarefas diárias.</p>
      </router-link>

      <router-link to="/user/usuarios/:id"
        class="bg-gradient-to-br from-teal-400 via-green-500 to-blue-500 p-5 sm:p-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
        <h3 class="text-xl sm:text-2xl font-semibold text-white mb-2">Configurações</h3>
        <p class="text-sm sm:text-base text-white">Gerencie suas configurações e preferências.</p>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import AuthService from '@/service/authService';

const username = ref('');

const fetchUsername = async () => {
  try {
    const currentUser = AuthService.getCurrentUser();

    if (!currentUser) {
      throw new Error('Usuário ou token ausente.');
    }

    const response = await axios.get(`https://web-production-54e4.up.railway.app/api/users/${currentUser.usuario_id}`, {
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });

    if (response.data && response.data.username) {
      username.value = response.data.username;
    } else {
      console.error('Dados do usuário ausentes.');
    }
  } catch (error) {
    console.error('Erro ao buscar o nome do usuário:', error);
    alert('Erro ao buscar o nome do usuário. Verifique se você está logado.');
  }
};

onMounted(() => {
  fetchUsername();
});
</script>

<style scoped>
.grid-cols-2 {
  display: grid;
}

@media (min-width: 768px) {
  .grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.shadow-lg {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.transition-transform {
  transition: transform 0.3s ease-in-out;
}

.hover\:scale-105:hover {
  transform: scale(1.05);
}
</style>
