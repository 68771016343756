<template>
  <div class="max-w-4xl mx-auto p-4 sm:p-6 bg-gray-50 rounded-lg shadow-lg">
    <h2 class="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-center text-gray-700">Lista de Dinheiro no Cofre</h2>

    <div class="flex flex-col sm:flex-row justify-between mb-4 sm:mb-6 space-y-4 sm:space-y-0">
      <button @click="showAddDinheiroCofreModal"
              class="bg-purple-500 text-white py-2 px-4 sm:px-6 rounded-lg hover:bg-purple-600 transition-all duration-300 shadow-sm text-sm sm:text-base">
        Adicionar Dinheiro
      </button>
    </div>

    <div class="w-full">
      <table class="w-full border border-gray-300 rounded-lg shadow-sm">
        <thead>
        <tr class="bg-purple-100">
          <th class="border px-2 py-2 text-gray-600 text-sm sm:text-base">Valor</th>
          <th class="border px-2 py-2 text-gray-600 text-sm sm:text-base">Ações</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="dinheiroCofre in dinheiroCofreList" :key="dinheiroCofre.id" class="hover:bg-gray-100 transition-all duration-300">
          <td class="border px-2 py-2 text-sm">R$ {{ dinheiroCofre.valor.toFixed(2) }}</td>
          <td class="border px-2 py-2 text-sm">
            <button @click="editDinheiroCofre(dinheiroCofre)"
                    class="text-yellow-500 hover:text-yellow-700 transition-colors duration-300 text-xs sm:text-sm">
              Editar
            </button>
            <button @click="deleteDinheiroCofre(dinheiroCofre.id)"
                    class="text-red-500 hover:text-red-700 transition-colors duration-300 ml-2 sm:ml-4 text-xs sm:text-sm">
              Excluir
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <transition name="fade">
      <div v-if="showModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div class="bg-white rounded-lg shadow-xl p-4 sm:p-6 max-w-md w-full">
          <h3 class="text-lg sm:text-xl font-semibold mb-4 sm:mb-6 text-gray-700">{{ isEditing ? 'Editar Dinheiro no Cofre' : 'Adicionar Dinheiro' }}</h3>
          <form @submit.prevent="handleSubmit">
            <div class="mb-4">
              <label class="block text-sm font-semibold text-gray-600 mb-2">Valor</label>
              <input v-model.number="dinheiroCofre.valor" type="number" required
                     class="border border-gray-300 px-3 py-2 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm sm:text-base"
                     placeholder="Valor" />
            </div>
            <div class="flex justify-end space-x-2 sm:space-x-4">
              <button type="submit"
                      class="bg-purple-500 text-white py-2 px-4 rounded-lg hover:bg-purple-600 transition-all duration-300 text-sm sm:text-base">
                {{ isEditing ? 'Atualizar' : 'Adicionar' }}
              </button>
              <button @click="closeModal" type="button"
                      class="bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400 transition-all duration-300 text-sm sm:text-base">
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const dinheiroCofreList = ref([]);
const showModal = ref(false);
const isEditing = ref(false);
const dinheiroCofre = ref({
  valor: 0,
});
const apiUrl = 'https://web-production-54e4.up.railway.app/dinheirocofre';

const fetchDinheiroCofre = async () => {
  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    dinheiroCofreList.value = response.data;
  } catch (error) {
    console.error('Erro ao buscar dinheiro no cofre:', error);
  }
};

const showAddDinheiroCofreModal = () => {
  resetForm();
  showModal.value = true;
};

const handleSubmit = async () => {
  try {
    if (isEditing.value) {
      await axios.put(`${apiUrl}/${dinheiroCofre.value.id}`, dinheiroCofre.value, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    } else {
      await axios.post(apiUrl, dinheiroCofre.value, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    }
    fetchDinheiroCofre();
    closeModal();
  } catch (error) {
    console.error('Erro ao salvar dinheiro no cofre:', error);
    alert('Erro ao salvar dinheiro no cofre.');
  }
};

const editDinheiroCofre = (dinheiroCofreToEdit) => {
  dinheiroCofre.value = {...dinheiroCofreToEdit};
  isEditing.value = true;
  showModal.value = true;
};

const deleteDinheiroCofre = async (id) => {
  if (confirm('Tem certeza que deseja excluir este dinheiro no cofre?')) {
    try {
      await axios.delete(`${apiUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      fetchDinheiroCofre();
    } catch (error) {
      console.error('Erro ao excluir dinheiro no cofre:', error);
      alert('Erro ao excluir dinheiro no cofre.');
    }
  }
};

const closeModal = () => {
  showModal.value = false;
};

const resetForm = () => {
  dinheiroCofre.value = {
    valor: 0,
  };
  isEditing.value = false;
};

onMounted(fetchDinheiroCofre);
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

table {
  border-collapse: collapse;
}

table th, table td {
  text-align: left;
}

button {
  transition: all 0.3s ease;
}
</style>
