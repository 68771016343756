<template>
  <div class="max-w-full sm:max-w-4xl mx-auto p-4 sm:p-6 bg-gray-50 rounded-lg shadow-lg">
    <h2 class="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-center text-gray-700">Lista de Gastos</h2>

    <div class="flex flex-col sm:flex-row justify-between mb-4 sm:mb-6 space-y-4 sm:space-y-0">
      <button @click="showAddGastoModal"
        class="bg-purple-500 text-white py-2 px-4 sm:px-6 rounded-lg hover:bg-purple-600 transition-all duration-300 shadow-sm text-sm sm:text-base">
        Adicionar Gasto
      </button>

      <div class="flex items-center space-x-4">
        <div>
          <label for="filterAno" class="text-sm font-semibold text-gray-600 mr-2">Filtrar por Ano:</label>
          <select id="filterAno" v-model="anoSelecionado" @change="filtrarPorMes"
            class="border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm sm:text-base">
            <option value="">Todos os anos</option>
            <option v-for="ano in anosDisponiveis" :key="ano" :value="ano">{{ ano }}</option>
          </select>
        </div>

        <div>
          <label for="filterMes" class="text-sm font-semibold text-gray-600 mr-2">Filtrar por Mês:</label>
          <select id="filterMes" v-model="mesSelecionado" @change="filtrarPorMes"
            class="border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm sm:text-base">
            <option value="">Todos os meses</option>
            <option v-for="mes in meses" :key="mes.valor" :value="mes.valor">{{ mes.nome }}</option>
          </select>
        </div>
      </div>
    </div>


    <div class="w-full">
      <table class="table-fixed w-full border border-gray-300 rounded-lg shadow-sm">
        <thead>
          <tr class="bg-purple-100">
            <th class="w-1/4 border px-2 py-2 text-gray-600 text-xs sm:text-base">Descrição</th>
            <th class="w-1/5 border px-2 py-2 text-gray-600 text-xs sm:text-base">Valor</th>
            <th class="w-1/5 border px-2 py-2 text-gray-600 text-xs sm:text-base">Data de Pagamento</th>
            <th class="w-1/5 border px-2 py-2 text-gray-600 text-xs sm:text-base">Status</th>
            <th class="w-1/5 border px-2 py-2 text-gray-600 text-xs sm:text-base">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="gasto in sortedGastos" :key="gasto.id" class="hover:bg-gray-100 transition-all duration-300">
            <td class="border px-2 py-2 text-xs sm:text-sm truncate">{{ gasto.descricao }}</td>
            <td class="border px-2 py-2 text-xs sm:text-sm truncate">R$ {{ gasto.valor.toFixed(2) }}</td>
            <td class="border px-2 py-2 text-xs sm:text-sm truncate">{{ formatDate(gasto.dataPagamento) }}</td>
            <td :class="getStatusClass(gasto)" class="border px-2 py-2 text-xs sm:text-sm truncate">
              {{ gasto.status }}
            </td>
            <td class="border px-2 py-2 text-xs sm:text-sm truncate">
              <button @click="editGasto(gasto)"
                class="text-blue-500 hover:text-yellow-700 transition-colors duration-300 text-xs sm:text-sm">
                Editar
              </button>
              <button @click="deleteGasto(gasto.id)"
                class="text-red-500 hover:text-red-700 transition-colors duration-300 ml-2 sm:ml-4 text-xs sm:text-sm">
                Excluir
              </button>
            </td>
          </tr>
          <tr>
            <td colspan="5" class="border px-2 py-2 text-right font-bold text-xs sm:text-base">
              Total: R$ {{ totalGastos.toFixed(2) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <transition name="fade">
      <div v-if="showModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div class="bg-white rounded-lg shadow-xl p-4 sm:p-6 max-w-md w-full">
          <h3 class="text-lg sm:text-xl font-semibold mb-4 sm:mb-6 text-gray-700">{{ isEditing ? 'Editar Gasto' :
            'Adicionar Gasto' }}</h3>
          <form @submit.prevent="handleSubmit">
            <div class="mb-4">
              <label class="block text-sm font-semibold text-gray-600 mb-2">Descrição</label>
              <input v-model="gasto.descricao" type="text" required
                class="border border-gray-300 px-3 py-2 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm sm:text-base"
                placeholder="Descrição do Gasto" />
            </div>
            <div class="mb-4">
              <label class="block text-sm font-semibold text-gray-600 mb-2">Valor</label>
              <input v-model.number="gasto.valor" type="number" required
                class="border border-gray-300 px-3 py-2 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm sm:text-base"
                placeholder="Valor" />
            </div>
            <div class="mb-4">
              <label class="block text-sm font-semibold text-gray-600 mb-2">Data de Pagamento</label>
              <input v-model="gasto.dataPagamento" type="date" required
                class="border border-gray-300 px-3 py-2 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm sm:text-base" />
            </div>
            <div class="mb-4">
              <label class="block text-sm font-semibold text-gray-600 mb-2">Status</label>
              <select v-model="gasto.status" required
                class="border border-gray-300 px-3 py-2 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm sm:text-base">
                <option value="PENDENTE">Pendente</option>
                <option value="PAGO">Pago</option>
                <option value="CONGELADO">Congelado</option>
              </select>
            </div>
            <div class="flex justify-end space-x-2 sm:space-x-4">
              <button type="submit"
                class="bg-purple-500 text-white py-2 px-4 rounded-lg hover:bg-purple-600 transition-all duration-300 text-sm sm:text-base">
                {{ isEditing ? 'Atualizar' : 'Adicionar' }}
              </button>
              <button @click="closeModal" type="button"
                class="bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400 transition-all duration-300 text-sm sm:text-base">
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';

const gastos = ref([]);
const gastosFiltrados = ref([]);
const showModal = ref(false);
const isEditing = ref(false);
const gasto = ref({
  descricao: '',
  valor: 0,
  dataPagamento: '',
  status: 'PENDENTE',
});
const apiUrl = 'https://web-production-54e4.up.railway.app/gastos';

const meses = [
  { nome: 'Janeiro', valor: '01' },
  { nome: 'Fevereiro', valor: '02' },
  { nome: 'Março', valor: '03' },
  { nome: 'Abril', valor: '04' },
  { nome: 'Maio', valor: '05' },
  { nome: 'Junho', valor: '06' },
  { nome: 'Julho', valor: '07' },
  { nome: 'Agosto', valor: '08' },
  { nome: 'Setembro', valor: '09' },
  { nome: 'Outubro', valor: '10' },
  { nome: 'Novembro', valor: '11' },
  { nome: 'Dezembro', valor: '12' },
];

const mesSelecionado = ref('');
const anoSelecionado = ref(''); 

const getMesAtual = () => {
  const dataAtual = new Date();
  return String(dataAtual.getMonth() + 1).padStart(2, '0');
};

const getAnoAtual = () => {
  const dataAtual = new Date();
  return String(dataAtual.getFullYear());
};

const fetchGastos = async () => {
  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    gastos.value = response.data;
    filtrarPorMes(); 
  } catch (error) {
    console.error('Erro ao buscar gastos:', error);
  }
};

const showAddGastoModal = () => {
  resetForm();
  showModal.value = true;
};

const handleSubmit = async () => {
  try {
    if (isEditing.value) {
      await axios.put(`${apiUrl}/${gasto.value.id}`, gasto.value, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    } else {
      await axios.post(apiUrl, gasto.value, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    }
    fetchGastos();
    closeModal();
  } catch (error) {
    console.error('Erro ao salvar gasto:', error);
    alert('Erro ao salvar gasto.');
  }
};

const editGasto = (gastoToEdit) => {
  gasto.value = { ...gastoToEdit };
  isEditing.value = true;
  showModal.value = true;
};

const deleteGasto = async (id) => {
  if (confirm('Tem certeza que deseja excluir este gasto?')) {
    try {
      await axios.delete(`${apiUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      fetchGastos();
    } catch (error) {
      console.error('Erro ao excluir gasto:', error);
      alert('Erro ao excluir gasto.');
    }
  }
};

const closeModal = () => {
  showModal.value = false;
};

const resetForm = () => {
  gasto.value = {
    descricao: '',
    valor: 0,
    dataPagamento: '',
    status: 'PENDENTE',
  };
  isEditing.value = false;
};

const filtrarPorMes = () => {
  gastosFiltrados.value = gastos.value.filter(gasto => {
    const [year, month] = gasto.dataPagamento.split('-');
    const mesMatches = mesSelecionado.value === '' || month === mesSelecionado.value;
    const anoMatches = anoSelecionado.value === '' || year === anoSelecionado.value;
    return mesMatches && anoMatches;
  });
};

const formatDate = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
};

const getStatusClass = (gasto) => {
  const today = new Date().toISOString().split('T')[0];
  if (gasto.status === 'PENDENTE' && gasto.dataPagamento < today) {
    return 'text-red-500';
  } else if (gasto.status === 'PAGO') {
    return 'text-green-500';
  } else if (gasto.status === 'CONGELADO') {
    return 'text-blue-500';
  } else if (gasto.status === 'PENDENTE') {
    return 'text-yellow-500';
  }
  return '';
};

const totalGastos = computed(() => {
  return gastosFiltrados.value.reduce((total, gasto) => {
    return gasto.status !== 'CONGELADO' ? total + gasto.valor : total;
  }, 0);
});

const sortedGastos = computed(() => {
  // eslint-disable-next-line
  return gastosFiltrados.value.sort((a, b) => new Date(a.dataPagamento) - new Date(b.dataPagamento));
});

const anosDisponiveis = computed(() => {
  const anosSet = new Set();
  gastos.value.forEach(gasto => {
    const ano = gasto.dataPagamento.split('-')[0];
    anosSet.add(ano);
  });
  return Array.from(anosSet).sort();
});

onMounted(() => {
  mesSelecionado.value = getMesAtual();
  anoSelecionado.value = getAnoAtual(); 
  fetchGastos();
});
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

table {
  border-collapse: collapse;
}

table th, table td {
  text-align: left;
}

button {
  transition: all 0.3s ease;
}
</style>