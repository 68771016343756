<template>
  <div class="max-w-4xl mx-auto p-4 sm:p-6 bg-white rounded-lg shadow-lg">
    <h2 class="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-center text-gray-700">Relatório Financeiro</h2>

    <div class="mb-4 sm:mb-6 flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:justify-between">
      <div class="flex items-center space-x-4">
        <label for="anoSelecionado" class="text-lg font-bold text-gray-700">Filtrar por Ano:</label>
        <select id="anoSelecionado" v-model="anoSelecionado" @change="filtrarPorMes"
          class="border rounded-lg px-4 py-2 text-sm sm:text-base">
          <option value="">Todos os anos</option>
          <option v-for="ano in anosDisponiveis" :key="ano" :value="ano">{{ ano }}</option>
        </select>
      </div>

      <div class="flex items-center space-x-4">
        <label for="mesSelecionado" class="text-lg font-bold text-gray-700">Filtrar por Mês:</label>
        <select id="mesSelecionado" v-model="mesSelecionado" @change="filtrarPorMes"
          class="border rounded-lg px-4 py-2 text-sm sm:text-base">
          <option value="">Todos os meses</option>
          <option v-for="mes in meses" :key="mes.valor" :value="mes.valor">{{ mes.nome }}</option>
        </select>
      </div>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6 mb-4 sm:mb-6">
      <div class="p-4 bg-purple-100 rounded-lg shadow-sm">
        <h3 class="text-lg sm:text-xl font-semibold text-gray-700">Total de Despesas</h3>
        <p class="text-xl sm:text-2xl font-bold text-red-600">R$ {{ totalDespesas.toFixed(2) }}</p>
      </div>
      <div class="p-4 bg-green-100 rounded-lg shadow-sm">
        <h3 class="text-lg sm:text-xl font-semibold text-gray-700">Total de Receitas</h3>
        <p class="text-xl sm:text-2xl font-bold text-green-600">R$ {{ totalReceitas.toFixed(2) }}</p>
      </div>
      <div class="p-4 bg-yellow-100 rounded-lg shadow-sm">
        <h3 class="text-lg sm:text-xl font-semibold text-gray-700">Previsão de Economia</h3>
        <p class="text-xl sm:text-2xl font-bold text-yellow-600">R$ {{ previsaoEconomia.toFixed(2) }}</p>
      </div>
    </div>

    <div class="mb-4 sm:mb-6">
      <h3 class="text-lg sm:text-2xl font-bold mb-2 sm:mb-4 text-gray-700">Detalhes de Gastos e Receitas</h3>
      <table class="w-full border border-gray-300 rounded-lg shadow-sm">
        <thead>
          <tr class="bg-gray-100">
            <th class="border px-2 sm:px-4 py-2 text-gray-600 text-sm sm:text-base">Descrição</th>
            <th class="border px-2 sm:px-4 py-2 text-gray-600 text-sm sm:text-base">Valor</th>
            <th class="border px-2 sm:px-4 py-2 text-gray-600 text-sm sm:text-base">Data</th>
            <th class="border px-2 sm:px-4 py-2 text-gray-600 text-sm sm:text-base">Tipo</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in itensFiltrados" :key="item.id" :class="item.tipo === 'Receita' ? 'bg-green-50' : ''"
            class="hover:bg-gray-100 transition-all duration-300">
            <td class="border px-2 sm:px-4 py-2 text-sm">{{ item.descricao }}</td>
            <td class="border px-2 sm:px-4 py-2 text-sm">R$ {{ item.valor.toFixed(2) }}</td>
            <td class="border px-2 sm:px-4 py-2 text-sm">{{ formatDate(item.data) }}</td>
            <td class="border px-2 sm:px-4 py-2 text-sm">{{ item.tipo }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="flex flex-col sm:flex-row justify-end space-y-4 sm:space-y-0 sm:space-x-4 mt-4 sm:mt-6">
      <button @click="exportarPDF"
        class="bg-red-500 text-white py-2 px-4 sm:px-6 rounded-lg hover:bg-red-600 transition-all duration-300 text-sm sm:text-base">
        Exportar PDF
      </button>
      <button @click="exportarExcel"
        class="bg-green-500 text-white py-2 px-4 sm:px-6 rounded-lg hover:bg-green-600 transition-all duration-300 text-sm sm:text-base">
        Exportar Excel
      </button>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

const totalDespesas = ref(0);
const totalReceitas = ref(0);
const previsaoEconomia = computed(() => totalReceitas.value - totalDespesas.value);
const itensRelatorio = ref([]);
const itensFiltrados = ref([]);
const mesSelecionado = ref('');
const anoSelecionado = ref(''); 

const meses = [
  { nome: 'Janeiro', valor: '01' },
  { nome: 'Fevereiro', valor: '02' },
  { nome: 'Março', valor: '03' },
  { nome: 'Abril', valor: '04' },
  { nome: 'Maio', valor: '05' },
  { nome: 'Junho', valor: '06' },
  { nome: 'Julho', valor: '07' },
  { nome: 'Agosto', valor: '08' },
  { nome: 'Setembro', valor: '09' },
  { nome: 'Outubro', valor: '10' },
  { nome: 'Novembro', valor: '11' },
  { nome: 'Dezembro', valor: '12' }
];

const getMesAtual = () => {
  const dataAtual = new Date();
  return String(dataAtual.getMonth() + 1).padStart(2, '0');
};

const getAnoAtual = () => {
  const dataAtual = new Date();
  return String(dataAtual.getFullYear());
};

const fetchRelatorio = async () => {
  try {
    const despesasResponse = await axios.get('https://web-production-54e4.up.railway.app/gastos', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    const receitasResponse = await axios.get('https://web-production-54e4.up.railway.app/receitas', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    const despesasComTipo = despesasResponse.data.map(gasto => ({
      ...gasto,
      tipo: 'Despesa',
      data: gasto.dataPagamento,
    }));

    const receitasComTipo = receitasResponse.data.map(receita => ({
      ...receita,
      tipo: 'Receita',
      data: receita.dataRecebimento,
    }));

    itensRelatorio.value = [...despesasComTipo, ...receitasComTipo];
    filtrarPorMes(); 
  } catch (error) {
    console.error('Erro ao buscar o relatório:', error);
  }
};

const filtrarPorMes = () => {
  itensFiltrados.value = itensRelatorio.value.filter(item => {
    const [year, month] = item.data.split('-');
    const mesMatches = mesSelecionado.value === '' || month === mesSelecionado.value;
    const anoMatches = anoSelecionado.value === '' || year === anoSelecionado.value;
    return mesMatches && anoMatches;
  });

  totalDespesas.value = itensFiltrados.value
    .filter(item => item.tipo === 'Despesa')
    .reduce((total, item) => total + item.valor, 0);

  totalReceitas.value = itensFiltrados.value
    .filter(item => item.tipo === 'Receita')
    .reduce((total, item) => total + item.valor, 0);
};

const anosDisponiveis = computed(() => {
  const anosSet = new Set();
  itensRelatorio.value.forEach(item => {
    const ano = item.data.split('-')[0];
    anosSet.add(ano);
  });
  return Array.from(anosSet).sort();
});

const exportarPDF = () => {
  const doc = new jsPDF();

  doc.setFontSize(18);
  doc.text('Relatório Financeiro', 14, 20);

  doc.autoTable({
    startY: 30,
    head: [['Data', 'Descrição', 'Valor', 'Tipo']],
    body: itensFiltrados.value.map(item => [
      formatDate(item.data),
      item.descricao,
      `R$ ${item.valor.toFixed(2)}`,
      item.tipo
    ]),
    theme: 'striped',
    headStyles: {fillColor: [30, 144, 255]},
    footStyles: {fillColor: [30, 144, 255]},
    showFoot: 'lastPage',
    foot: [
      ['Total', '', `R$ ${totalDespesas.value.toFixed(2)}`, 'Despesa'],
      ['', '', `R$ ${totalReceitas.value.toFixed(2)}`, 'Receita']
    ]
  });

  const currentDate = new Date().toLocaleDateString('pt-BR');
  doc.setFontSize(10);
  doc.text(`Relatório gerado em: ${currentDate}`, 14, doc.internal.pageSize.height - 10);
  doc.save('relatorio-financeiro.pdf');
};

const exportarExcel = () => {
  const worksheetData = [
    ['Data', 'Descrição', 'Valor', 'Tipo'],
    ...itensFiltrados.value.map(item => [
      formatDate(item.data),
      item.descricao,
      `R$ ${item.valor.toFixed(2)}`,
      item.tipo
    ])
  ];

  const ws = XLSX.utils.aoa_to_sheet(worksheetData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Relatório Financeiro');

  const currentDate = new Date().toLocaleDateString('pt-BR').replace(/\//g, '-');
  XLSX.writeFile(wb, `relatorio-financeiro-${currentDate}.xlsx`);
};

const formatDate = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
};

onMounted(() => {
  mesSelecionado.value = getMesAtual();
  anoSelecionado.value = getAnoAtual(); 
  fetchRelatorio();
});
</script>
