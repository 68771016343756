import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router/index.js';
import '../src/css/main.css';
import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core';
import {  faTasks, faHome, faUser, faBuilding, faPhone, faSignInAlt, faQuestion, faRobot, faQuestionCircle, faMoneyBillWave, faChartLine, faPiggyBank, faCreditCard, faFileInvoiceDollar, faFileAlt, faBars, faCalendarAlt, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'; // Adicione `faCalendarAlt`
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

axios.defaults.baseURL = 'https://web-production-54e4.up.railway.app';
axios.interceptors.request.use(
    config => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.token) {
            config.headers.Authorization = 'Bearer ' + user.token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

library.add(faHome, faUser, faBuilding, faPhone, faSignInAlt, faQuestion, faRobot, faQuestionCircle, faMoneyBillWave, faChartLine, faPiggyBank, faCreditCard, faFileInvoiceDollar, faFileAlt, faBars, faCalendarAlt, faChevronLeft, faChevronRight, faTasks);

const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(router);
app.mount('#app');
