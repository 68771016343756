<template>
  <div class="max-w-4xl mx-auto p-4 sm:p-6 bg-gray-50 rounded-lg shadow-lg">
    <h2 class="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-center text-gray-700">Lista de Saldo em Bolsa</h2>

    <div class="flex flex-col sm:flex-row justify-between mb-4 sm:mb-6 space-y-4 sm:space-y-0">
      <button @click="showAddSaldoBolsaModal"
        class="bg-purple-500 text-white py-2 px-4 sm:px-6 rounded-lg hover:bg-purple-600 transition-all duration-300 shadow-sm text-sm sm:text-base">
        Adicionar Saldo
      </button>
    </div>

    <!-- Remover overflow-x e ajustar largura para evitar rolagem lateral -->
    <div class="w-full">
      <table class="w-full border border-gray-300 rounded-lg shadow-sm">
        <thead>
          <tr class="bg-purple-100">
            <th class="border px-2 py-2 text-gray-600 text-sm sm:text-base">Descrição</th>
            <th class="border px-2 py-2 text-gray-600 text-sm sm:text-base">Valor</th>
            <th class="border px-2 py-2 text-gray-600 text-sm sm:text-base">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="saldoBolsa in saldoBolsaList" :key="saldoBolsa.id"
            class="hover:bg-gray-100 transition-all duration-300">
            <td class="border px-2 py-2 text-sm">{{ saldoBolsa.descricao }}</td>
            <td class="border px-2 py-2 text-sm">R$ {{ saldoBolsa.valor.toFixed(2) }}</td>
            <td class="border px-2 py-2 text-sm">
              <button @click="editSaldoBolsa(saldoBolsa)"
                class="text-yellow-500 hover:text-yellow-700 transition-colors duration-300 text-xs sm:text-sm">
                Editar
              </button>
              <button @click="deleteSaldoBolsa(saldoBolsa.id)"
                class="text-red-500 hover:text-red-700 transition-colors duration-300 ml-2 sm:ml-4 text-xs sm:text-sm">
                Excluir
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal responsivo -->
    <transition name="fade">
      <div v-if="showModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div class="bg-white rounded-lg shadow-xl p-4 sm:p-6 max-w-md w-full">
          <h3 class="text-lg sm:text-xl font-semibold mb-4 sm:mb-6 text-gray-700">{{ isEditing ? 'Editar Saldo em Bolsa'
            : 'Adicionar Saldo' }}</h3>
          <form @submit.prevent="handleSubmit">
            <div class="mb-4">
              <label class="block text-sm font-semibold text-gray-600 mb-2">Descrição</label>
              <input v-model="saldoBolsa.descricao" type="text" required
                class="border border-gray-300 px-3 py-2 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm sm:text-base"
                placeholder="Descrição" />
            </div>
            <div class="mb-4">
              <label class="block text-sm font-semibold text-gray-600 mb-2">Valor</label>
              <input v-model.number="saldoBolsa.valor" type="number" required
                class="border border-gray-300 px-3 py-2 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm sm:text-base"
                placeholder="Valor" />
            </div>
            <div class="flex justify-end space-x-2 sm:space-x-4">
              <button type="submit"
                class="bg-purple-500 text-white py-2 px-4 rounded-lg hover:bg-purple-600 transition-all duration-300 text-sm sm:text-base">
                {{ isEditing ? 'Atualizar' : 'Adicionar' }}
              </button>
              <button @click="closeModal" type="button"
                class="bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400 transition-all duration-300 text-sm sm:text-base">
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>


<script setup>
import {ref, onMounted} from 'vue';
import axios from 'axios';

const saldoBolsaList = ref([]);
const showModal = ref(false);
const isEditing = ref(false);
const saldoBolsa = ref({
  descricao: '',
  valor: 0,
});
const apiUrl = 'https://web-production-54e4.up.railway.app/saldos-bolsa';

const fetchSaldoBolsa = async () => {
  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    saldoBolsaList.value = response.data;
  } catch (error) {
    console.error('Erro ao buscar saldo em bolsa:', error);
  }
};

const showAddSaldoBolsaModal = () => {
  resetForm();
  showModal.value = true;
};

const handleSubmit = async () => {
  try {
    if (isEditing.value) {
      await axios.put(`${apiUrl}/${saldoBolsa.value.id}`, saldoBolsa.value, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    } else {
      await axios.post(apiUrl, saldoBolsa.value, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    }
    fetchSaldoBolsa();
    closeModal();
  } catch (error) {
    console.error('Erro ao salvar saldo em bolsa:', error);
    alert('Erro ao salvar saldo em bolsa.');
  }
};

const editSaldoBolsa = (saldoBolsaToEdit) => {
  saldoBolsa.value = {...saldoBolsaToEdit};
  isEditing.value = true;
  showModal.value = true;
};

const deleteSaldoBolsa = async (id) => {
  if (confirm('Tem certeza que deseja excluir este saldo em bolsa?')) {
    try {
      await axios.delete(`${apiUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      fetchSaldoBolsa();
    } catch (error) {
      console.error('Erro ao excluir saldo em bolsa:', error);
      alert('Erro ao excluir saldo em bolsa.');
    }
  }
};

const closeModal = () => {
  showModal.value = false;
};

const resetForm = () => {
  saldoBolsa.value = {
    descricao: '',
    valor: 0,
  };
  isEditing.value = false;
};

onMounted(fetchSaldoBolsa);
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

table {
  border-collapse: collapse;
}

table th, table td {
  text-align: left;
}

button {
  transition: all 0.3s ease;
}
</style>
