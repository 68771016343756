<template>
  <div class="flex flex-col min-h-screen">
    <header class="bg-purple-950 text-gray-200 p-4 flex justify-between items-center shadow-md">
      <h1 class="text-lg font-semibold ml-4 flex items-center">
        <font-awesome-icon icon="chart-line" class="mr-2 text-yellow-400" /> ControlEase
      </h1>
      <div class="hidden md:flex space-x-6">
        <div>
          <p class="text-sm">Despesas: <span class="font-semibold">R$ {{ totalDespesas.toFixed(2) }}</span></p>
        </div>
        <div>
          <p class="text-sm">Receita: <span class="font-semibold">R$ {{ totalReceitas.toFixed(2) }}</span></p>
        </div>
        <div>
          <p class="text-sm">Economia: <span class="font-semibold text-green-400">R$ {{ previsaoEconomia.toFixed(2)
              }}</span></p>
        </div>
      </div>
      <nav class="flex space-x-3 items-center">
        <router-link to="/user/usuarios/:id">
          <font-awesome-icon icon="user" class="text-xl hover:text-gray-400 transition duration-300" />
        </router-link>
        <router-link to="/">
          <button @click="logout"
            class="bg-red-500 hover:bg-red-600 text-sm text-white font-semibold py-1 px-3 rounded shadow-md transition">
            <font-awesome-icon icon="sign-in-alt" class="mr-1" /> Sair
          </button>
        </router-link>
        <button @click="toggleMenu" class="md:hidden text-white">
          <font-awesome-icon icon="bars" class="text-2xl" />
        </button>
      </nav>
    </header>

    <div v-if="menuAberto" class="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden" @click="toggleMenu"></div>

    <div class="flex flex-1">
      <div
        :class="['md:static md:flex md:w-60 w-60 bg-purple-950 text-gray-200 transform transition-transform duration-300 ease-in-out', menuAberto ? 'translate-x-0 fixed z-50 inset-y-0' : '-translate-x-full fixed z-50 inset-y-0 md:translate-x-0']">
        <div class="p-4 space-y-2">
          <router-link to="/user/dashboard" class="block px-4 py-2 rounded hover:bg-purple-700 text-sm">
            <font-awesome-icon icon="home" class="mr-2" /> Início
          </router-link>
          <router-link to="/user/gastos" class="block px-4 py-2 rounded hover:bg-purple-700 text-sm">
            <font-awesome-icon icon="money-bill-wave" class="mr-2" /> Gastos
          </router-link>
          <router-link to="/user/receitas" class="block px-4 py-2 rounded hover:bg-purple-700 text-sm">
            <font-awesome-icon icon="money-bill-wave" class="mr-2" /> Receitas
          </router-link>
          <router-link to="/user/saldo-banco" class="block px-4 py-2 rounded hover:bg-purple-700 text-sm">
            <font-awesome-icon icon="credit-card" class="mr-2" /> Saldo no Banco
          </router-link>
          <router-link to="/user/dinheiro-cofre" class="block px-4 py-2 rounded hover:bg-purple-700 text-sm">
            <font-awesome-icon icon="piggy-bank" class="mr-2" /> Cofre
          </router-link>
          <router-link to="/user/aplicacoes" class="block px-4 py-2 rounded hover:bg-purple-700 text-sm">
            <font-awesome-icon icon="chart-line" class="mr-2" /> Aplicações
          </router-link>
          <router-link to="/user/saldo-bolsa" class="block px-4 py-2 rounded hover:bg-purple-700 text-sm">
            <font-awesome-icon icon="file-invoice-dollar" class="mr-2" /> Bolsa
          </router-link>
          <router-link to="/user/tarefas" class="block px-4 py-2 rounded hover:bg-purple-700 text-sm">
            <font-awesome-icon icon="tasks" class="mr-2" /> Tarefas
          </router-link>
          <router-link to="/user/calendario" class="block px-4 py-2 rounded hover:bg-purple-700 text-sm">
            <font-awesome-icon icon="calendar-alt" class="mr-2" /> Calendário
          </router-link>
          <router-link to="/user/relatorios" class="block px-4 py-2 rounded hover:bg-purple-700 text-sm">
            <font-awesome-icon icon="file-alt" class="mr-2" /> Relatórios
          </router-link>
        </div>
      </div>

      <div class="flex-grow p-6 bg-gray-100">
        <router-view />
      </div>
    </div>

    <footer class="bg-purple-950 text-gray-300 text-center p-3 text-xs">
      © 2024 ControlEase. Todos os direitos reservados.
    </footer>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

const router = useRouter();
const totalDespesas = ref(0);
const totalReceitas = ref(0);
const mesSelecionado = ref('');
const menuAberto = ref(false);

const isLargeScreen = ref(window.innerWidth >= 768);

const toggleMenu = () => {
  menuAberto.value = !menuAberto.value;
};

window.addEventListener('resize', () => {
  if (window.innerWidth >= 768) {
    menuAberto.value = false;
    isLargeScreen.value = true;
  } else {
    isLargeScreen.value = false;
  }
});

const obterMesAtual = () => {
  const dataAtual = new Date();
  return String(dataAtual.getMonth() + 1).padStart(2, '0');
};

const previsaoEconomia = computed(() => totalReceitas.value - totalDespesas.value);

const fetchTotais = async () => {
  try {
    const despesasResponse = await axios.get('https://web-production-54e4.up.railway.app/gastos', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    const receitasResponse = await axios.get('https://web-production-54e4.up.railway.app/receitas', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (mesSelecionado.value) {
      const despesasFiltradas = despesasResponse.data.filter(gasto => gasto.dataPagamento.split('-')[1] === mesSelecionado.value);
      const receitasFiltradas = receitasResponse.data.filter(receita => receita.dataRecebimento.split('-')[1] === mesSelecionado.value);

      totalDespesas.value = despesasFiltradas.reduce((total, gasto) => total + gasto.valor, 0);
      totalReceitas.value = receitasFiltradas.reduce((total, receita) => total + receita.valor, 0);
    } else {
      totalDespesas.value = despesasResponse.data.reduce((total, gasto) => total + gasto.valor, 0);
      totalReceitas.value = receitasResponse.data.reduce((total, receita) => total + receita.valor, 0);
    }
  } catch (error) {
    console.error('Erro ao buscar totais:', error.response?.data?.message || error.message);
  }
};

onMounted(() => {
  mesSelecionado.value = obterMesAtual();
  fetchTotais();
});

const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('usuario_id');
  router.push('/');
};
</script>
